import React from "react";
import { Link } from "react-router-dom";
import "./usefulStyles.css";

type Props = {
    image: string,
    title: string,
    description: string,
    linkName: string | any
}
const UsefulThingsCard: React.FC<Props> = ({image, title, description, linkName}) => {
    return (
        <Link
            to={`/useful_things/${linkName}`}
            // @ts-ignore
            params={{ cardname: linkName }}
        >
            <div className="card">
                <img src={image} alt={title}/>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </Link>
    );
};
export default UsefulThingsCard;
