import React from "react";
import { Link } from "react-router-dom";
import "./cvStyles.css";

type Props = {
    setCheckMatch: (val: string) => void
}
const CvCheck: React.FC<Props> = ({ setCheckMatch }) => {
    return (
        <div className="checkContent">
            <h3>
                My CV contains personal information that I do not want to share
                with everyone. Enter the code I gave you and it will be visible
                immediately.
            </h3>
            <input
                type="text"
                name=""
                id=""
                onChange={(e) => setCheckMatch(e.target.value)}
            />
            <h3>If you do not have a code, contact me, thank you.</h3>
            <Link to="/contact">
                <button className="prymary-btn">CONTACT ME</button>
            </Link>
        </div>
    );
};

export default CvCheck;
