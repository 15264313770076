import React, { useState } from "react";
import { nanoid } from "nanoid";
import todoItems from "../../../Data/TodoData";
import './todosStyles.css'
import { TodoType } from "../../../Types/Types";

// type TT = {
//     id?: string ,
//     title?: string ,
//     description?: string 
// }

const Todos: React.FC = () => {
    const [todos, setTodos] = useState<TodoType[]>(todoItems)
    const [newTodo, setNewTodo] = useState<{ [x: string]: string }>()
    
    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewTodo({...newTodo, [e.target.name] : e.target.value})
    } 
    
    const textareaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setNewTodo({...newTodo, [e.target.name] : e.target.value})
    } 

    const completedTodoHandler = (id: string) => {
        const newList = [...todos]
        let i = newList.findIndex(todo => todo.id === id)
        newList[i].completed = !newList[i].completed
        setTodos( newList );
    };
    const deleteTodoHandler = (id: string) => {
        setTodos( (prev: any) => prev.filter( (todo: TodoType) => todo.id !== id ) )
    };
    const addTodoHandler = (event: React.FormEvent) => {
        event.preventDefault();
        if(newTodo?.title && newTodo?.description) {
            let item = {
                id: nanoid(),
                title: newTodo?.title,
                description: newTodo?.description,
                completed: false
            }
            setTodos([...todos, item])
        }
    };

    return (
        <div className='max-5'>
            <h1 className='text-to-center'>Todo list</h1>
            <ul className="todoList">
                { todos.map( (todo: TodoType) => (
                    <li
                        className={`todoListItem ${ !todo.completed ? {} : "todoCompleted" }`
                        }
                        key={todo.id}
                    >
                        <div onClick={() => completedTodoHandler(todo.id)} > {todo.title}</div>
                        <span
                            className="removeTodoItem"
                            onClick={() => deleteTodoHandler(todo.id)}
                        >
                            X
                        </span>
                        <br></br>
                        <span className="todoItemDescription">
                            {todo.description}
                        </span>
                    </li>
                ))}
            </ul>
            <div className="addTodoForm">
                <h3 className='text-to-center'>Add todo</h3>
                <form onSubmit={(e) => addTodoHandler(e)}>
                    <input
                        type="text"
                        name="title"
                        value={ newTodo?.title || ''}
                        placeholder="Title"
                        onChange={(e) => inputChangeHandler(e)}
                        required
                    />
                    <textarea
                        name="description"
                        value={ newTodo?.description || '' }
                        rows={2}
                        placeholder="Description"
                        onChange={(e) => textareaChangeHandler(e)}
                    ></textarea>
                    <div>
                        <button type="submit">Add</button>
                    </div>                    
                </form>
            </div>
        </div>
    );
}
export default Todos