import React, { useState } from "react";

type Calculated = {
    productPrice: number,
    people: number,
    month: number,
}
const OneMillionCalculator: React.FC = () => {
    const [calculated, setCalculated] = useState<Calculated>({
        productPrice: 1000000,
        people: 1,
        month: 1,
    }); 
    let value: number

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        switch (e.target.name) {
            case "price": 
                value = parseInt(
                    (
                        1000000 /
                        parseInt(e.target.value) /
                        calculated.month
                    ).toFixed(1),
                );
                setCalculated((prev) => ({
                    ...prev,
                    productPrice: parseInt(e.target.value),
                    people: value,
                }));
                break;
            case "person":
                value = parseInt(
                    (
                        1000000 /
                        parseInt(e.target.value) /
                        calculated.month
                    ).toFixed(2),
                );
                setCalculated((prev) => ({
                    ...prev,
                    people: parseInt(e.target.value),
                    productPrice: value,
                }));
                break;
            case "month":
                value = parseInt(
                    (
                        1000000 /
                        calculated.productPrice /
                        parseInt(e.target.value)
                    ).toFixed(1),
                );
                setCalculated((prev) => ({
                    ...prev,
                    month: parseInt(e.target.value),
                    people: value,
                }));
                break;
            default:
                break;
        }
    };

    return (
        <div className="pages-container max-5">
            <h1 className="text-to-center">How to earn 1 million $</h1>
            <div>
                <h2>
                    You need one product with price $ {calculated.productPrice}
                </h2>
                <input
                    type="number"
                    name="price"
                    min="1"
                    step="0.01"
                    value={calculated.productPrice}
                    onChange={(e) => inputChangeHandler(e)}
                />
                <h2>
                    You need to sell this product to {calculated.people}{" "}
                    person(s)
                </h2>
                <input
                    type="number"
                    name="person"
                    min="1"
                    step="1"
                    value={calculated.people}
                    onChange={(e) => inputChangeHandler(e)}
                />
                <h2>
                    If you can sell this mounthly for {calculated.month}{" "}
                    month(s)
                </h2>
                <input
                    type="number"
                    name="month"
                    min="1"
                    step="1"
                    value={calculated.month}
                    onChange={(e) => inputChangeHandler(e)}
                />
            </div>
        </div>
    );
};

export default OneMillionCalculator;
