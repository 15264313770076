import React, { useState } from "react";
import './percentageCalculatorStyles.css'

const PercentageCalculator: React.FC = () =>  {
    const [value, setValue] = useState<number>(100)
    const [percent, setPercent] = useState<number>(100)
    const [brutresult, setBrutresult] = useState<number>(100)
    const [netresult, setNetresult] = useState<number>(100)

    const heandlerPercent = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPercent(Number(event.target.value))

        let b = (value * Number(event.target.value) / 100).toFixed(2); 
        let n = (value * 100 / Number(event.target.value)).toFixed(2);
        
        setBrutresult(Number(b))
        setNetresult(Number(n))
    }
    const heandlerValue = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(Number(event.target.value))
        
        let b = (Number(event.target.value) * percent / 100).toFixed(1); 
        let n = (Number(event.target.value) * 100 / percent).toFixed(1);
        
        setBrutresult(Number(b))
        setNetresult(Number(n))

    }
    return (
        <div className="pages-container max-5">
            <h1 className="text-to-center">Percentage calculator</h1>
            <div className="percResult">
                <div>{brutresult}</div>
                <div>{netresult}</div>
                <span>Number {value} `s {percent} percentage is:</span>
                <span>Number {value} is {percent} percent of:</span>
            </div>
            <div className="perc-inputs">
                <label htmlFor="">The number</label>
                <input
                    type="number"
                    value={value}
                    onChange={heandlerValue}
                />
                <label htmlFor="">Percentage</label>
                <input
                    type="number"
                    min={1}
                    max={10000}
                    value={percent}
                    onChange={heandlerPercent}
                />                    
            </div>
        </div>
    );
}
export default PercentageCalculator