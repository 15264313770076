import React, { useState } from "react";
import AddExpenseForm from "./AddExpenseForm";
import Budget from "./Budget";
import ExpensList from "./ExpensList";
import Remain from "./Remain";
import Spent from "./Spent";
import budgetData from "../../../Data/BudgetData";
import "./budgetStyles.css";
import { BudgetType } from "../../../Types/Types";

const BudgetApp: React.FC = () => {
    const [budget, setBudget] = useState<number>(300000);
    const [expenses, setExpenses] = useState<BudgetType[]>(budgetData);

    const addExpense = (newExpense: BudgetType): void => {
        setExpenses([...expenses, newExpense]);
    };
    const deleteExpense = (id: String) => {
        setExpenses((prev: BudgetType[]) => prev.filter((expense: BudgetType) => expense.id !== id));
    };

    return (
        <div className="budgetContainer">
            <h1>Budget planner</h1>
            <div className="moneyContainer">
                <Budget budget={budget} setBudget={setBudget} />
                <Remain budget={budget} expenses={expenses} />
                <Spent expenses={expenses} />
            </div>
            <h3>Expenses</h3>
            <ExpensList expenses={expenses} deleteExpense={deleteExpense} />
            <h3>Add expense</h3>
            <AddExpenseForm addExpense={addExpense} />
        </div>
    );
};

export default BudgetApp;
