const questions: any = [
    {
        questionText: 'What is the capital of Hungary?',
        answerOptions: [
            { answerText: 'New Yourk', isCorrect: false },
            { answerText: 'London', isCorrect: false },
            { answerText: 'Budapest', isCorrect: true },
            { answerText: 'Bucharest', isCorrect: false },
        ]
    },
    {
        questionText: 'Who is the CEO of Tesla?',
        answerOptions: [
            { answerText: 'Jeff Bezos', isCorrect: false },
            { answerText: 'Elon Musk', isCorrect: true },
            { answerText: 'Bill Gates', isCorrect: false },
            { answerText: 'Abraham Linkoln', isCorrect: false },
        ]
    },
    {
        questionText: 'The iPhone was created by witch company?',
        answerOptions: [
            { answerText: 'Apple', isCorrect: true },
            { answerText: 'Intel', isCorrect: false },
            { answerText: 'Amazon', isCorrect: false },
            { answerText: 'Motorola', isCorrect: false },
        ]
    },
    {
        questionText: 'How many Harry Potter books are there?',
        answerOptions: [
            { answerText: '2', isCorrect: false },
            { answerText: '3', isCorrect: false },
            { answerText: '6', isCorrect: false },
            { answerText: '7', isCorrect: true },
        ]
    }
]
export default questions