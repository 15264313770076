import React, { Component } from "react";
import TheSnake from "./TheSnake";
import Food from "./Food";
import "./snakeStyles.css";

const getRandomCoordinates = () => {
    let min = 1;
    let max = 98;
    let x = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
    let y = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
    return [x, y];
};

const initialState = {
    food: getRandomCoordinates(),
    speed: 200,
    direction: "RIGHT",
    snakeDots: [
        [0, 0],
        [2, 0],
    ],
};

export default class Snake extends Component {
    state = initialState;

    componentDidMount() {
        setInterval(this.moveSnake, this.state.speed);
        // @ts-ignore
        document.onkeydown = this.onKeyDown;
    }

    componentDidUpdate() {
        this.checkIfOutOfBorders();
        this.checkIfCollapsed();
        this.checkIfEat();
    }

    onKeyDown = (e: React.KeyboardEvent): void => {
        e = e || window.event;
        switch (Number(e.keyCode)) {
            case 38:
                this.setState({ direction: "UP" });
                break;
            case 40:
                this.setState({ direction: "DOWN" });
                break;
            case 37:
                this.setState({ direction: "LEFT" });
                break;
            case 39:
                this.setState({ direction: "RIGHT" });
                break;
        }
    };

    moveSnake = () => {
        let dots = [...this.state.snakeDots];
        let head = dots[dots.length - 1];

        switch (this.state.direction) {
            case "RIGHT":
                head = [head[0] + 2, head[1]];
                break;
            case "LEFT":
                head = [head[0] - 2, head[1]];
                break;
            case "DOWN":
                head = [head[0], head[1] + 2];
                break;
            case "UP":
                head = [head[0], head[1] - 2];
                break;
        }
        dots.push(head);
        dots.shift();
        this.setState({
            snakeDots: dots,
        });
    };

    checkIfOutOfBorders() {
        let head = this.state.snakeDots[this.state.snakeDots.length - 1];
        if (head[0] >= 100 || head[1] >= 100 || head[0] < 0 || head[1] < 0) {
            this.onGameOver();
        }
    }

    checkIfCollapsed() {
        let snake = [...this.state.snakeDots];
        let head = snake[snake.length - 1];
        snake.pop();
        snake.forEach((dot) => {
            if (head[0] === dot[0] && head[1] === dot[1]) {
                this.onGameOver();
            }
        });
    }

    checkIfEat() {
        let head = this.state.snakeDots[this.state.snakeDots.length - 1];
        let food = this.state.food;
        if (head[0] === food[0] && head[1] === food[1]) {
            this.setState({
                food: getRandomCoordinates(),
            });
            this.enlargeSnake();
            this.increaseSpeed();
        }
    }

    enlargeSnake() {
        let newSnake = [...this.state.snakeDots];
        newSnake.unshift([]);
        this.setState({
            snakeDots: newSnake,
        });
    }

    increaseSpeed() {
        if (this.state.speed > 10) {
            this.setState({
                speed: this.state.speed - 10,
            });
        }
    }

    onGameOver() {
        alert(`Game Over. Snake length is ${this.state.snakeDots.length}`);
        this.setState(initialState);
    }

    render() {
        return (
            <div className="pages-container">
                <h1 className="text-to-center">Snake</h1>
                <div className="gameArea">
                    <TheSnake snakeDots={this.state.snakeDots} />
                    <Food dot={this.state.food} />
                </div>
                <h3 className="text-to-center">You can control the snake with keyboard arrows: Up , Down , Left , Right</h3>
                <h2 className="text-to-center">Snake length: {this.state.snakeDots.length}</h2>
            </div>
        );
    }
}

// import { useEffect, useState } from "react";
// import classes from './snakeStyles.module.css'
// import Food from "./Food";
// import TheSnake from "./TheSnake";

// const Snake = () =>  {
//     const getRandomCoordinates = () => {
//         let min = 1;
//         let max = 98;
//         let x = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
//         let y = Math.floor((Math.random() * (max - min + 1) + min) / 2) * 2;
//         return [x, y];
//     };
//     const [food, setFood] = useState(getRandomCoordinates())
//     const [speed, setSpeed] = useState(200)
//     const [direction, setDirection] = useState('RIGHT')
//     const [snakeDots, setSnakeDots] = useState([
//         [0, 0],
//         [2,0]
//     ])

//     const moveSnake = () => {
//         let dots = [...snakeDots]
//         let head = dots[dots.length - 1];

//         switch (direction) {
//             case "RIGHT":
//                 head = [head[0] + 2, head[1]];
//                 break;
//             case "LEFT":
//                 head = [head[0] - 2, head[1]];
//                 break;
//             case "DOWN":
//                 head = [head[0], head[1] + 2];
//                 break;
//             case "UP":
//                 head = [head[0], head[1] - 2];
//                 break;
//         }
//         dots.push(head);
//         dots.shift();
//         setSnakeDots(dots)
//     };

//     useEffect(() => {
//         checkIfOutOfBorders();
//         checkIfCollapsed();
//         checkIfEat();
//     }, [snakeDots])
    
//     // componentDidUpdate() {
//     //     this.checkIfOutOfBorders();
//     //     this.checkIfCollapsed();
//     //     this.checkIfEat();
//     // }


//     const onKeyDown = (e) => {
//         e = e || window.event;
//         switch (e.keyCode) {
//             case 38:
//                 setDirection('UP');
//                 break;
//             case 40:
//                 setDirection('DOWN');
//                 break;
//             case 37:
//                 setDirection('LEFT');
//                 break;
//             case 39:
//                 setDirection('RIGH');
//                 break;
//         }
//     };

//     const checkIfOutOfBorders = () => {
//         let head = snakeDots[snakeDots.length - 1];
//         if (head[0] >= 100 || head[1] >= 100 || head[0] < 0 || head[1] < 0) {
//             onGameOver();
//         }
//     }

//     const checkIfCollapsed = () => {
//         let snake = [...snakeDots];
//         let head = snake[snake.length - 1];
//         snake.pop();
//         snake.forEach((dot) => {
//             if (head[0] == dot[0] && head[1] == dot[1]) {
//                 onGameOver();
//             }
//         });
//     }

//     const checkIfEat = () => {
//         let head = snakeDots[(snakeDots.length - 1)];
//         let food = [...food];
//         if ( head[0] == food[0] && head[1] == food[1] ) {
//             setFood( getRandomCoordinates() );
//             enlargeSnake();
//             increaseSpeed();
//         }
//     }

//     const enlargeSnake = () => {
//         let newSnake = [...snakeDots];
//         newSnake.unshift([]);
//         setSnakeDots(newSnake)
//     }

//     const increaseSpeed = () => {
//         if (speed > 10) {
//             this.setState({
//                 speed: speed - 10,
//             });
//         }
//     }
//     // useEffect(()=>{
//     //     startGame()
//     // },[])
//     const startGame = () => {
//         setInterval(moveSnake(), speed);
//         let a = {keyCode: 'RIGHT'}
//         document.onkeydown = onKeyDown('RIGHT');
//     }

//     const onGameOver = () => {
//         alert(`Game Over. Snake length is ${snakeDots.length}`);
//         setState(initialState);
//     }

//     return (
//         <div className="pages-container" onKeyDown={(e) => onKeyDown(e)}>
//             <h1 className="text-to-center">Snake</h1>
//             <div className={classes.gameArea}>
//                 <TheSnake snakeDots={snakeDots} />
//                 <Food dot={food} />
//             </div>
//             <h3 className="text-to-center">You can control the snake with keyboard arrows: Up , Down , Left , Right</h3>
//             <h2 className="text-to-center">Snake length: {snakeDots.length}</h2>
//             <button onClick={startGame}>Start</button>
//         </div>
//     );
// }
// export default Snake