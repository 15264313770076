import { NoteType } from "../Types/Types"
const notesData: NoteType[] = [
    {
        id: "jsdio",
        text: "www.ddax.site Don't forget.",
        createdAt: "9/8/2022"
    },
    {
        id: "jsdasio",
        text: "The big bang teory TV serries",
        createdAt: "9/12/2022"
    },
    {
        id: "jsdio12ws",
        text: "Bread , milk, eggs",
        createdAt: "1/2/2022"
    },
    {
        id: "jsdiqwo",
        text: "R = U / I ",
        createdAt: "5/3/2022"
    },
    {
        id: "jsdpoio",
        text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        createdAt: "9/8/2022"
    },
]
export default notesData