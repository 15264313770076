import React, { useState } from 'react'
import questions from '../../../Data/QuestionsData'
import './quizStyles.css'

const Quiz: React.FC = () => {
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    const [score, setScore] = useState<number>(0);
    const [showScore, setShowScore] = useState<boolean>(false)

    const handleAnswers = (isCorrect: boolean) => {
        if (isCorrect) { 
            setScore(score + 1) 
        }
        if (currentQuestion + 1 < questions.length) {
            setCurrentQuestion(currentQuestion + 1)
        } else {
            setShowScore((prevScoreValue) => !prevScoreValue)
        }
    }

    const restart = () => {
        setCurrentQuestion(0)
        setScore(0)
        setShowScore(false)
    }

  return (
    <div className="quizContainer">
        { showScore ? (
            <div className="scoreSection">
                You ansvered {score} correct from {questions.length} question
                <button onClick={restart}>Restart</button>
            </div>
        ) : (
            <>
                <div className="questionSection">
                    <div className="questionCount">
                        <span>Question {currentQuestion + 1} </span>/ {questions.length}
                    </div>
                    <div className="questionText">{questions[currentQuestion].questionText}</div>
                </div>
                <div className="answerSection">
                    { questions[currentQuestion].answerOptions.map((answerOption: any, i: number) => 
                        <button
                            key={i}
                            onClick={() => handleAnswers(answerOption.isCorrect)}
                        >{answerOption.answerText}</button>
                    ) }
                </div>
            </>
        ) }
    </div>
  )
}

export default Quiz