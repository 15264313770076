import React from 'react'
import { useState } from 'react'
import dataForCv from '../../../Data/CvData'
import './cvStyles.css'

const CvContent: React.FC = () => {    
    const [cvData, setCvData] = useState<any>(dataForCv)

    return (
        <div className="cvContainer">
            <div className="profil">  
                <img className="profilImg" src={ cvData.personal.profilImgSrc }  alt="" />
                <div className="profilName">  
                    <h1>{ cvData.personal.name }</h1>
                    <h3>{ cvData.personal.profession }</h3>
                </div>
            </div>
            <div className="contact">
                <h2>Contact info</h2>
                <div className="contactInfo">
                    <div><b>Phone: </b>{ cvData.personal.phone }</div>
                    <div><b>E-mail: </b>{ cvData.personal.email }</div>
                    <div><b>Country: </b>{ cvData.personal.country }</div>
                    <div><b>LinkedIn: </b>{ cvData.personal.linkedInLink }</div>
                    <div><b>Facebook: </b>{ cvData.personal.facebookLink }</div>
                </div>
            </div>
            <div className="summary">
                <div className="showControl" 
                    onClick={() => setCvData( {...cvData, showSummary: !cvData.showSummary }) }
                >
                    <h2>Summary</h2>
                    { cvData.showSummary ? (<span>&#x25B2;</span>) : (<span>&#x25BC;</span>) }
                </div>
                { cvData.showSummary && ( cvData.summary ) }
                
            </div>
            <div className="workExperienceContainer">
                <div className="showControl"
                    onClick={() => setCvData( {...cvData, showWorkExperiences: !cvData.showWorkExperiences }) }
                >
                    <h2>Work experience</h2>
                    { cvData.showWorkExperiences ? (<span>&#x25B2;</span>) : (<span>&#x25BC;</span>) }
                </div>
                { cvData.showWorkExperiences && ( 
                    cvData.workExperiences.map((workExperience: any, a: number) => (
                        <div key={a}>
                            <div className="experienceHeader">
                                <h3>{ workExperience.companiName } { workExperience.position }</h3>
                                <h3>{ workExperience.dates }</h3>
                            </div>
                            <div>
                                { workExperience.project.map((project: any, b: number) => (
                                    <div key={b} className="experienceContainer">
                                        <h4><b><u>Project:</u> </b>{ project.projectName }</h4><br />
                                        <h4><b><u>{ project.role } with tasks:</u> </b>
                                            <ul>
                                                { project.tasks.map((task: any, c: number) => (
                                                <li key={c}>{ task }</li>
                                            )) }
                                            </ul>
                                            
                                        </h4><br />
                                        <h4><b><u>Used technologies:</u> </b>{ project.usedTechnologies }</h4><br />

                                    </div>
                                )) }
                            </div>
                        </div>
                    )) 
                ) }
                
            </div>
            
            <div className="educationContainer">
                <div className="showControl"
                    onClick={() => setCvData( {...cvData, showEdication: !cvData.showEdication }) }
                >
                    <h2>Education</h2>
                    { cvData.showEdication ? (<span>&#x25BC;</span>) : (<span>&#x25BC;</span>) }
                </div>
                { cvData.showEdication && ( 
                    cvData.education.map((course: any, i: number) => (
                        <div key={i}>{ course.date } - <span>{ course.schoolname }</span> { course.material }</div>
                    ))
                )}
            </div>
            
            <div className="skillsContainer">
                <div className="showControl" 
                        onClick={() => setCvData( {...cvData, showSkills: !cvData.showSkills }) }
                    >
                    <h2>Skills</h2>
                    { cvData.showSkills ? (<span>&#x25B2;</span>) : (<span>&#x25BC;</span>) }
                </div>
                { cvData.showSkills && (
                    cvData.skills.map((skill: any, i: number) => (
                        <div key={i} className="skillItem"><b>{ skill.skillname }: </b>{skill.skillitems.map((item: any, j: number) => (
                                <p key={j}>{ item }, </p>
                            ))
                        }
                        </div>
                    ))
                )}
            </div>
        </div>
    )
}

export default CvContent