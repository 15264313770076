import React from 'react'
import { RiSearchLine } from 'react-icons/ri'
import './notesStyles.css'
// import SearchIcon from '@mui/icons-material/Search';

type Props = {
  handleSearchNote: (val: string) => void
}

const SearchNotes: React.FC<Props> = ({handleSearchNote}) => {
  return (
      <div className="notesHeader">
          <div className="search">
            {/* <SearchIcon /> */}
            <button className='icon-button'><RiSearchLine /></button>
            <input onChange={ (e) => handleSearchNote(e.target.value) } type='text' placeholder='Search note ...'/>
        </div>
      </div>
    
  )
}

export default SearchNotes