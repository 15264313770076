import React, { useState } from "react";
import './morseStyles.css'

const Convert: any = {
    A: ".-",
    B: "-...",
    C: "-.-.",
    D: "-..",
    E: ".",
    F: "..-.",
    G: "--.",
    H: "....",
    I: "..",
    J: ".---",
    K: "-.-",
    L: ".-..",
    M: "--",
    N: "-.",
    O: "---",
    P: ".--.",
    Q: "--.-",
    R: ".-.",
    S: "...",
    T: "-",
    U: "..-",
    W: ".--",
    X: "-..-",
    Y: "-.--",
    Z: "--..",
};

const Morse: React.FC = () => {
    const [str, setStr] = useState<string>("");
    const [morseCode, setMorseCode] = useState<string>("");
    const [char, setChar] = useState<string>("")

    const convertToMorse = (string: string) => {
        let m = string
            .toUpperCase()
            .split("")
            .map((el) => {
                return Convert[el] ? Convert[el] : el;
            })
            .join(" ");
        setMorseCode(m);
    };

    const convertToSting = (mcode: string) => {
        let s = mcode.split(" ").map((el) => {
            return Object.keys(Convert).find((key) => Convert[key] === el);
        });
        setStr(s.join(""));
    };
    const spaceHandler = () => {
        let newString = morseCode + char + ' '
        setMorseCode(newString)
        convertToSting(newString)
        setChar("")
    }

    return (
        <div className="pages-container max-5">
            <h1>Morse code tranlator</h1>
            <h3 className='text-to-center'>Enter text here</h3>
            <input
                type="text"
                name=""
                onChange={(e) => convertToMorse(e.target.value)}
            />
            <h2>{morseCode}</h2>
            <h3 className='text-to-center'>Enter morse code here by using . - and space to separate the letters</h3>
            <input
                type="text"
                name=""
                pattern="(-. )"
                onChange={(e) => convertToSting(e.target.value)}
            />
            <h2 className='text-to-center'>{str}</h2>
            
            <h3>New letter code: <span>{char}</span></h3>
            
            <div className="morse-buttons">
                <div className="morse-btn-container">
                    <button className="morse-button" onClick={() => setChar(char + '.')}>.</button>
                    <button className="morse-button" onClick={() => setChar(char + '-')}>-</button>
                </div>
                <button className="morse-button morse-space-btn" onClick={spaceHandler}>Space</button>
            </div>
            <button className="center-btn" onClick={()=> {setStr(""); setChar(""); setMorseCode("")}}>Clear</button>
        </div>
    );
};

export default Morse;
