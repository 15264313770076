import React from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./budgetStyles.css";

type Props = {
    id: string,
    name: string,
    cost: number,
    deleteExpense: (val: string) => void
}

const ExpenseItem: React.FC<Props> = ({ id, name, cost, deleteExpense }) => {
    return (
        <li>
            {name}
            <div className="budgetListButtons">
                {cost} Ft
                <button
                    className="icon-button"
                    onClick={() => deleteExpense(id)}
                >
                    <RiDeleteBin6Line />
                </button>
            </div>
        </li>
    );
};

export default ExpenseItem;
