import React from 'react'
import { RiDeleteBin6Line } from 'react-icons/ri'
import './notesStyles.css'

type Props = {
  id: string,
  text: string,
  createdAt: string,
  deleteNote: (val: string) => void
}
const Note: React.FC<Props> = ({id, text, createdAt, deleteNote}) => {
  return (
    <div className="note">
        <span>{text}</span>
        <div className="noteFooter">
            <small>{createdAt}</small>
            <button className='icon-button' onClick={() => deleteNote(id)} ><RiDeleteBin6Line /></button>
        </div>
    </div>
  )
}

export default Note