import React, { useState } from 'react'
import './notesStyles.css'

type Props = {
    addNote: (val: string) => void
}

const AddNote: React.FC<Props> = ({addNote}) => {
    const [noteText, setNoteText] = useState<string>('')
    const charakterLimit: number = 200

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        if (charakterLimit - e.target.value.length >= 0) {
            setNoteText(e.target.value)
        }
    } 
    const handleSaveClick = (): void => {
        if (noteText.trim().length > 0) {
            addNote(noteText)
            setNoteText('')
        }
    }
    return (
        <div className="note new">
            <textarea 
                cols={38}
                rows={10}
                placeholder='Type note text here...'
                className="addNoteTextarea"
                value={noteText}
                onChange={handleChange}    
            >
            </textarea>
            <div className="noteFooter">
                <small>{charakterLimit - noteText.length} remaining</small>
                <button className="save small-btn" onClick={handleSaveClick}>Save</button>
            </div>
        </div>
    )
}

export default AddNote