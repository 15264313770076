import React from "react";
import { BudgetType } from "../../../Types/Types";
import "./budgetStyles.css";

type Props = {
    expenses: any
    budget: number
}

const Remain: React.FC<Props> = ({ expenses, budget}: any) => {
    const totalExpenses = expenses.reduce((total: number, item: BudgetType) => total + item.cost, 0);
    return (
        <div
            className={`showMoney ${ totalExpenses > budget ? "alert" : "success"
            }`}
        >
            <span>Remaining: {budget - totalExpenses} FT</span>
        </div>
    );
};

export default Remain;
