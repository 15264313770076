import React, { useState } from "react";
import "./budgetStyles.css";

type Props = {
    budget: number,
    setBudget: any
}

const Budget = ({ budget, setBudget }: Props) => {
    const [newBudget, setNewBudget] = useState<number>(0);
    const editBudget = () => {
        setBudget(newBudget);
        setNewBudget(0);
    };

    return (
        <div className="showMoney">
            <span>Budget: {budget} FT</span>
            {newBudget !== 0 && (
                <input
                    type="number"
                    value={newBudget}
                    onChange={(e) => setNewBudget(Number(e.target.value))}
                />
            )}
            {newBudget !== 0 ? (
                <button className="small-btn" onClick={editBudget}>
                    Save
                </button>
            ) : (
                <button
                    className="small-btn"
                    onClick={() => setNewBudget(budget)}
                >
                    Edit
                </button>
            )}
        </div>
    );
};

export default Budget;
