import { useState } from 'react'
import NotesList from './NotesList'
import SearchNotes from './SearchNotes'
import { nanoid } from 'nanoid'
import notesData from '../../../Data/NotesData'
import { NoteType } from '../../../Types/Types'
import './notesStyles.css'


const Notes: React.FC = () => {
    const [notes, setNotes] = useState<NoteType[]>(notesData)
    const [searchText, setSearchText] = useState<string>('')

    const addNote = (text: string): void => {
        const date = new Date()
        const newNote: NoteType = {
            id: nanoid(),
            text: text,
            createdAt: date.toLocaleDateString()
        }
        setNotes([...notes, newNote])
    }

    const deleteNote = (id: string) => {
        setNotes( prev => prev.filter((note) => note.id !== id ))
    }

    return (
        <div className="notesContainer">
            <SearchNotes handleSearchNote={setSearchText} />
            <NotesList 
                notes={
                    notes.filter( (note) => 
                        note.text.toLowerCase().includes(searchText)
                    )                
                } 
                addNote={addNote}
                deleteNote={deleteNote}
            />
        </div>
    )
}

export default Notes