import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import './contactForm.css'


const ContactForm: React.FC = () => {
    const [emailData, setEmailData] = useState<any>({})
    const recaptchaRef = React.createRef();

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEmailData({...emailData, [e.target.name] : e.target.value })
    };
    const textareaChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setEmailData({...emailData, [e.target.name] : e.target.value })
    };

    const submitHandler  = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
        
        //@ts-ignore
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE, process.env.REACT_APP_EMAILJS_TEMPLATE, emailData, process.env.REACT_APP_EMAILJS_KEY).then(
            function (response) {
                console.log("SUCCESS!", response.status, response.text);
                setEmailData({});
            },
            function (error) {
                console.log("FAILED...", error);
            }
        );
    };

    const onReCAPTCHAChange = (captchaCode:any) => {
        if(!captchaCode) {
          return;
        }
        //@ts-ignore
        recaptchaRef.current.reset();
      }

        return (
            <div className="contactFormContainer">
                <form onSubmit={(e) => submitHandler(e)}>
                    {/*@ts-ignore*/}
                    <ReCAPTCHA           
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_RECAPCHA_SITEKEY}
                        onChange={onReCAPTCHAChange}
                    />

                    <div className="inlineInputs">
                        <input
                            type="text"
                            className="grid-item"
                            name="name"
                            placeholder="Name"
                            onChange={(e) => inputChangeHandler(e)}
                        />
                        <input
                            type="email"
                            className="grid-item"
                            name="email"
                            placeholder="Email"
                            onChange={(e) => inputChangeHandler(e)}
                            required
                        />
                    </div>
                    <input
                        type="text"
                        name="subject"
                        placeholder="Subject"
                        onChange={(e) => inputChangeHandler(e)}
                    />
                    <textarea
                        name="message"
                        id=""
                        rows={5}
                        placeholder="Message"
                        onChange={(e) => textareaChangeHandler(e)}
                        required
                    ></textarea>
                    {emailData.errormessage}
                    <button type="submit" className="prymary-btn">
                        Send
                    </button>
                </form>
            </div>
        );

}
export default ContactForm