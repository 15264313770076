import React, { useEffect, useState } from "react";
import ExpenseItem from "./ExpenseItem";
import "./budgetStyles.css";
import { BudgetType } from "../../../Types/Types";

type Props = {
    expenses: BudgetType[],
    deleteExpense: (val: string) => void
}

const ExpensList: React.FC<Props> = ({ expenses, deleteExpense }) => {
    const [filteredExpenses, setFilteredExpenses] = useState<BudgetType[]>([]);

    useEffect(() => {
        setFilteredExpenses(expenses);
    }, [expenses]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const searchResult = expenses.filter((filteredExpense: BudgetType) =>
            filteredExpense.name.toLowerCase().includes(e.target.value.toLocaleLowerCase())
        );
        setFilteredExpenses(searchResult);
    };

    return (
        <div className="expensesList">
            <input
                type="text"
                onChange={(e) => handleSearch(e)}
                placeholder="Search..."
            />
            <ul>
                {filteredExpenses.map((expense) => (
                    <ExpenseItem
                        key={expense.id}
                        id={expense.id}
                        name={expense.name}
                        cost={expense.cost}
                        deleteExpense={deleteExpense}
                    />
                ))}
            </ul>
        </div>
    );
};

export default ExpensList;
