import React from 'react'
import { useParams } from "react-router-dom";
import * as Cards from './UsefulIndex'

type LinkName = {
    linkName: string;
  };

const UsefulContainer: React.FC = () => {
    const params = useParams<LinkName>()
    const a = params.linkName;
    // @ts-ignore
    const Card: any = Cards[a];
    return <Card />
}
export default UsefulContainer