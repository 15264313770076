const dataForCv: any = {
    personal: {
        name: 'Zoltan Acs',
        profession: 'Software engenier',
        phone: '+36 50 127 7959',
        email: 'ax.zoltan@gmail.com',
        country: 'Humgary',
        address: '1077, Budapest, str: Rozsa, nr: 37',
        facebookLink: 'www.facebook.com/ax.zoltan',
        linkedInLink: 'www.linkedin.com/in/zoltan-acs-developer',
        profilImgSrc: '/assets/images/IMG_2909.JPG',
    },
    showSummary: true,
    summary: '6+ years of experience as a software engineer, with a focus on web development. Proven track record in developing high-quality and scalable web applications. Expertise in HTML, CSS, JavaScript, ReactJS, VueJs, NodeJS, PHP and Python. Passionate about web standards and keeping up with the latest trends in technology.',
    showWorkExperiences: false,
    workExperiences: [
        {companiName: 'EPAM System', 
        position: 'Software Engineer primary skill Javascript',
        dates: 'Jan 2022 - Current',
        project: [
            { projectName: 'Not public',
            role: 'Software Engineer',
            tasks: [
                'Developing new features for existing project.',
                'Writing unit tests.',
                'Code review.'
            ],
            usedTechnologies: 'HTML, CSS, Material UI, Javascript,TypeScript, React, Redux, NextJs, ExpressJs, NodeJs, Jest, MongoDB, Jira, Scrum.' }
        ] },
        {companiName: 'Qubed Agency', 
        position: 'Senior Fullstack developer and project manager',
        dates: 'Jan 2021 - Jan 2022',
        project: [
            { projectName: 'Viastein ( Romanian, Hungarian, Italian Paving Factory )\nElena Keplinger ( Distributor of Romanian, Hungarian beauty care products )\nKyhealthsafety ( American first aid course company )\nMuzli ( Healthy food distributor in Romania )\nPavajdincauciuk ( Romanian rubber paving  distributor )\nDisc Diamant ( Distributor of Romanian diamond saw blades )\nQubed (Romanian branding company )\nFogklinika ( Romanian dental clinic )\nKortoves ( Traditional guest houses in Romania )\nFlyCityDelivery ( Romanian bicycle courier )\nFuture workforce ( American employment agency )\nHolzner ( Romanian Hotel )\nImpar ( Romanian car parts distributor )\nLegaservice ( German lawyer office )\nNorth east generator ( American construction and infrastructure construction company)\nPavajele ( Romanian, Hungarian Paving company )\nVizaeras ( IOT monitoring technology for healthy indoor environments )\nIwantobuild ( Australian construction company )',
            role: 'Full stack developer and project manager',
            tasks: [
                'Discuss what exactly the project needs to know.', 
                'Compile the tasks to be performed.', 
                'Estimate the time required.', 
                'Create the project on the developer server.', 
                'Help designers create the look of the project.', 
                'Perform coding tasks in frontend and backend databases.', 
                'Discuss any changes with clients.', 
                'Creating documentation for the use of apps.', 
                'Test and correct any errors.', 
                'Move the project to the client server.', 
                'Maintenance, updates and backups of existing projects.', 
                'Mentoring university interns.'
            ],
            usedTechnologies: 'HTM, CSS, Javascript, React, Material UI, PHP, mySql, Adobe XD, Adobe Photoshop, Adobe Illustrator, git, Ms Teams, C panel.' }
        ] },
        {companiName: 'Themarketive', 
        position: 'Fullstack developer',
        dates: 'Feb 2018 - Dec 2020',
        project: [
            { projectName: 'Hamm Provider of an online food ordering platform. The company offers services that enable internet users to make online orders at local restaurants and delivery as well.',
            role: 'Full stack developer',
            tasks: [
                'Discuss the necessary APIs with the mobile developers.',
                'To design the mySql database.',
                'Write the frontend and backend code of the administration panel alone.',
                'Test to fix the errors.',
                'Modify small parts to suit other teams.',
                'Participate in the discussions of the news sprints.',
                'Moving new items to the server with github.',
                'Mentoring two university interns.'
            ],
            usedTechnologies: 'HTML, CSS, Material UI, Javascript,TypeScript, React, Redux, NextJs, ExpressJs, NodeJs, Jest, MongoDB, Jira, Scrum.' 
            },
            { projectName: 'TmTime is an ERP system for a big company that distributes medicines, rents out properties and also owns a radio channel.',
            role: 'Full stack developer',
            tasks: [
                'Coding, testing code overview to monitor the utilization and finances of company-owned apartments.',
                'Collaboration with the team to integrate features should not cause problems.'
            ],
            usedTechnologies: 'HTML, CSS, Bootstrap, Javascript, React, PHP, Laravel, mySql, git, Sourcetree, Jira, Scrum.'
            },
            { projectName: 'Szeredapp. Official buildings description in Csikszereda city: hospitals, museums, theaters, banks, ATMs, hotels, nearby attractions, events. Official news portal. Issuance of official notices. Appointments.',
            role: 'Backend developer',
            tasks: [
                'Data collection and verification, uploaded to the database.',
                'Writing APIs for mobile application. Testing'
            ],
            usedTechnologies: 'HTML, CSS, PHP, Laravel, mySql, git, Sourcetree, Jira, Scrum.' }
        ] }
    ],
    showEdication: false,
    education: [
        { date: '2006', schoolname: 'Kos Karoly', material: 'Mathematics - Informatics' },
        { date: '2010', schoolname: 'Expert Titan Construct ltd', material: 'Construction Technician' },
        { date: '2016', schoolname: 'Manila Web Műhely', material: 'Web Developer' },
        { date: '2018', schoolname: 'Fejleszdmagad', material: 'UX/UI designer' },
        { date: '2018', schoolname: 'Fejleszdmagad', material: 'automation and IoT Course in python' },
        { date: '2020', schoolname: 'Sibaro', material: 'Electrician constructor' },
    ],
    showSkills: false,
    skills: [
        {skillname: 'Languages', skillitems: ['English B1','Hungarian C','Romanian B1']},
        {skillname: 'Technical Skills', skillitems: ['HTML', 'CSS', 'Bootstrap', 'Material UI', 'Javascript', 'React JS', 'Redux', 'Next JS', 'Express JS', 'Node JS', 'Vue JS', 'Jest', 'PHP', 'Laravel', 'Python', 'Django', 'MySql', 'MongoDB', 'Adobe XD', 'Adobe Photoshop', 'Adobe Illustrator', 'UI/UX Design', 'Github', 'Jira', 'Sourcetree', 'Scrum', 'Waterfall']},
        {skillname: 'Soft Skills', skillitems: ['Leadership', 'Creativity', 'Time Management', 'Mentoring', 'Problem solving', 'Communication']},
    ]

}
export default dataForCv