import React, { useState } from 'react'
import './termostatSyles.css'

const Termostat: React.FC = () => {
    const [temp, setTemp] = useState<number>(20)
    const [termostatTemp, setTermostatTemp] = useState(21)

    const controllsHandler = (arg: string): void => {
        if (arg === 'increase' && termostatTemp < 40 ) {
            setTermostatTemp(termostatTemp + 0.5)
        }
        if (arg === 'decrease' && termostatTemp > -10 ) {
            setTermostatTemp(termostatTemp - 0.5)
        }
    }

  return (
    <div className="termostatContainer">
        <div className={`temperature ${ termostatTemp < temp -1 && "cool" } ${ termostatTemp > temp +1 && "heat" }`}>
            <span className="roomTemp">{temp} &#8451;</span>
            <span className="temp">{termostatTemp} &#8451;</span>
            <span className="engin">
                { termostatTemp < temp -1 ? 'Cooler' : '' }
                { termostatTemp > temp +1 ? 'Heater' : '' }
                { (termostatTemp >= temp -1 && temp +1 >= termostatTemp) ? 'Off' : '' }
            </span>
        </div>
        <div className="controls">
            <button onClick={() => controllsHandler('decrease')}>-</button>
            <button onClick={() => controllsHandler('increase')}>+</button>
        </div>
        <input type='number' step='0.5' value={temp}  onChange={(e) => setTemp(Number(e.target.value))} />
    </div>
  )
}

export default Termostat