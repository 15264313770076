import React from 'react'
import { NoteType } from '../../../Types/Types'
import AddNote from './AddNote'
import Note from './Note'
import './notesStyles.css'

type Props = {
  notes: NoteType[],
  addNote: (val: string) => void
  deleteNote: (val: string) => void
}

const NotesList: React.FC<Props> = ({notes, addNote, deleteNote}) => {
  return (
    <div className="notesList">
        { notes.map( (note: NoteType) => (
            <Note key={note.id} id={note.id} text={note.text} createdAt={note.createdAt} deleteNote={deleteNote} />) 
        ) }
        <AddNote addNote={addNote}/>
    </div>
  )
}

export default NotesList