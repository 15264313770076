import React from 'react'
import './snakeStyles.css'
type Props = {
    snakeDots: number[][]
}
const TheSnake: React.FC<Props> = ({snakeDots}) => {
    return (
        <div>
            {snakeDots.map((dot: number[],i: number) => {
                const style ={
                    left: `${dot[0]}%`,
                    top: `${dot[1]}%`
                }
                return(
                    <div className="snakeDot" key={i} style={style}></div>
                )
            })}
        </div>
    )
}

export default TheSnake