import { nanoid } from "nanoid";
import { UsefulThingType } from "../Types/Types";

const usefulThingsData: UsefulThingType[] = [
    {
        _id: nanoid(),
        image: "/assets/images/onemillion.PNG",
        title: "One million",
        description:
            "Here you can find how to get your first million dollar",
        linkName: "one_million_calculator",
    },
    {
        _id: nanoid(),
        image: "/assets/images/passgen.JPG",
        title: "Password generator",
        description:
            "Here you can generate random numbers, stong passwords and any type of random words",
        linkName: "passgenerator",
    },
    {
        _id: nanoid(),
        image: "/assets/images/percentage.JPG",
        title: "Percentage calculator",
        description:
            "Here you can calculate any percentage of any number, brutto and also netto result",
        linkName: "percentage_calculator",
    },
    {
        _id: nanoid(),
        image: "/assets/images/Snake.JPG",
        title: "Snake",
        description:
            "Who don`t know this old style game SNAKE its a small project bat cool to play 5 minutes widouth stress",
        linkName: "snake",
    },
    {
        _id: nanoid(),
        image: "/assets/images/todos.JPG",
        title: "Todo list",
        description: "This is a great and fast todo application",
        linkName: "todos",
    },
    {
        _id: nanoid(),
        image: "/assets/images/calculator.PNG",
        title: "Calculator",
        description: "Simple calculator",
        linkName: "calculator",
    },    
    {
        _id: nanoid(),
        image: "/assets/images/morse.PNG",
        title: "Morse",
        description: "Morse code translator",
        linkName: "morse",
    },
    {
        _id: nanoid(),
        image: "/assets/images/quiz.PNG",
        title: "Quiz",
        description: "I's a small easy quiz to complete it",
        linkName: "quiz",
    },
    {
        _id: nanoid(),
        image: "/assets/images/notes.PNG",
        title: "Notes",
        description: "I's a small easy quiz to complete it",
        linkName: "notes",
    },
    {
        _id: nanoid(),
        image: "/assets/images/budget.PNG",
        title: "Budget",
        description: "App to fallow your mounthly expenses",
        linkName: "budgetapp",
    },
    {
        _id: nanoid(),
        image: "/assets/images/termostat.PNG",
        title: "Termostat",
        description: "A well designed termostat",
        linkName: "termostat",
    },
];
export default usefulThingsData