import React from 'react'
import { useState  } from 'react'
import CvCheck from './CvCheck'
import CvContent from './CvContent'

const CV: React.FC = () => {
    const [checkMatch, setCheckMatch] = useState<string>('')
    
    return (
        <div>
            {(checkMatch === 'z98x') ? <CvContent /> : <CvCheck setCheckMatch={setCheckMatch} />}
        </div>
    )
}

export default CV