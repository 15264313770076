import React, { useState } from "react";
import './passwordGeneratorStyles.css'

const Passgenerator: React.FC = () =>  {
    const [conditions, setConditions] = useState([
        { conditionText: 'Contain A-Z', conditionName: 'containBigChar', isTrue: true, conditionChars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' },
        { conditionText: 'Contain a-z', conditionName: 'containSmallChar', isTrue: true, conditionChars: 'abcdefghijklmnopqrstuvwxyz' },
        { conditionText: 'Contain numbers', conditionName: 'containNumbers', isTrue: true, conditionChars: '0123456789' },
        { conditionText: 'Contain parentheses', conditionName: 'containParentheses', isTrue: false, conditionChars: '{}()[]' },
        { conditionText: 'Contain math operators', conditionName: 'containOperation', isTrue: false, conditionChars: '+-*/<>' },
        { conditionText: 'Contain special characters', conditionName: 'containSpecials', isTrue: false, conditionChars: "?:,.!@#$%^&`~'" }
    ])
    const [passlength, setPasslength] = useState<number>(16)
    const [password, setPassword] = useState<string>('')

    const conditionChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const newConditions = [...conditions]
        let index = newConditions.findIndex((i) => i.conditionName === e.target.name)
        newConditions[index].isTrue = !newConditions[index].isTrue
        setConditions(newConditions)
    } 
    const generatePass = () => {
        let passwd = '';
        var chars = '';
        conditions.forEach( (condition) => {
            if ( condition.isTrue ) { chars += condition.conditionChars }
        })        
        for (var i = 1; i <= passlength; i++) {
            var c = Math.floor(Math.random() * chars.length + 1);
            passwd += chars.charAt(c);
        }
        setPassword( passwd )
    };
    const handlerCopy = () => {
        navigator.clipboard.writeText(password)
        var w: any = window.open('','','width=100,height=100')
        w.document.write('Copied!')
        w.focus()
        setTimeout(function() {w.close();}, 2000)
    }

    return (
        <div className="max-5">
            <h1 className="text-to-center">Password generator</h1>
            <div>
                { conditions.map((condition) => (
                    <label className="checkcontainer" key={condition.conditionText}>
                        <input
                            type="checkbox"
                            name={condition.conditionName}
                            checked={condition.isTrue}
                            onChange={(e) => conditionChangeHandler(e)}
                        />
                        {condition.conditionText}
                    </label>
                )) }                
                <input type="number" min={1} max={999} value={passlength} onChange={(e) => setPasslength(Number(e.target.value))}/>
                <button onClick={generatePass}>Generate</button>
                <div className="copyPassword" onClick={handlerCopy}>
                    <p>click to copy to clipboadr</p>
                    <h2>{password}</h2>
                </div>
                
            </div>
        </div>
    );
}

export default Passgenerator