import React from 'react'
import { BudgetType } from '../../../Types/Types'
import './budgetStyles.css'

type Props = {
    expenses: BudgetType[]
}
const Spent: React.FC<Props> = ({expenses}) => {

    const totalExpenses = expenses.reduce( (total: number, item: BudgetType) => total + item.cost, 0)
    return (
        <div className="showMoney">
            <span>Spent: {totalExpenses} FT</span>
        </div>
    )
}

export default Spent