import React from "react";
import { Route, Routes } from "react-router-dom";
import Navmenu from "./Components/Nav/Nav";
import Home from "./Components/Pages/Home/Home";
import Contact from "./Components/Pages/Contact/Contact";
import CV from "./Components/Pages/CV/CV";
import UsefulThings from "./Components/Pages/UsefulThings/UsefulThings";
import UsefulContainer from "./Components/Pages/UsefulThings/UsefulContainer";
import "./App.css";

const App: React.FC = () => {
    return (
        <div className="App">
            <Navmenu />
            <div className="container mainContainer">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/cv" element={<CV />} />
                    <Route path="/useful_things" >
                      <Route index element ={ <UsefulThings /> } />
                      <Route path=":linkName" element={<UsefulContainer />} />
                    </Route>
                    
                </Routes>
            </div>
        </div>
    );
}

export default App;
