import React from 'react'
import { Link } from "react-router-dom";
import "./homeStyles.css";

const Home: React.FC = () => {
    return (
        <div className="homeContainer">
            <div>
                <h1>
                    <p>Speech is a waste of time.</p>
                    <p>Let me show you some interesting</p>
                    <p>and useful things.</p>
                </h1>
                <Link to="/contact">
                    <button className="prymary-btn">CONTACT ME</button>
                </Link>
            </div>
        </div>
    );
};
export default Home;
