import React, { useState } from "react";
import UsefulThingsCard from "./UsefulThingsCard";
import { Outlet } from "react-router-dom";
import usefulThingsData from "../../../Data/UsefulThingsData";
import "./usefulStyles.css";
import { UsefulThingType } from "../../../Types/Types";

const UsefulThings: React.FC = () => {
    // eslint-disable-next-line
    const [usefulThings, setUsefulThings] = useState<UsefulThingType[]>(usefulThingsData);

    return (
        <div>
            <h1>Useful things</h1>

            <div className="cardContainer">
                {usefulThings.map((usefulThing) => (
                    <UsefulThingsCard
                        key={usefulThing._id}
                        image={usefulThing.image}
                        title={usefulThing.title}
                        description={usefulThing.description}
                        linkName={usefulThing.linkName}
                    />
                ))}
            </div>
            <Outlet />
        </div>
    );
};

export default UsefulThings;
