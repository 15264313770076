import React, { useState } from 'react'
import './calculatorStyles.css'

const Calculator: React.FC = () => {
    const [calc, setCalc] = useState<string>('');
    const [result, setResult] = useState<string>('');
    const ops = ['/', '*', '+', '-', '.'];

    const createDigits = () => {
        const digits = [];
        for ( let i=1; i < 10; i++ ) {
            digits.push(
                <button className="digitsBtn" onClick={() => updateCalc(i.toString())} key={i}>{i}</button>
            )
        }
        return digits
    }

    const updateCalc = (value: string) => {
        if (
            (ops.includes(value) && calc === '') ||
            (ops.includes(value) && ops.includes(calc.slice(-1)))
        ) { return }
        setCalc(calc + value);

        if ( !ops.includes(value) ) {
            // eslint-disable-next-line
            setResult(eval(calc + value).toString()); 
        }
    }

    const calculate = () => {
        // eslint-disable-next-line
        setCalc(eval(calc).toString());
        setResult('');
    }

    const deleteLast = () => {
        if ( calc === '' ) { return; }
        const value = calc.slice(0, -1);
        setCalc(value)
    }

    const resetAll = () => {
        setCalc('')
        setResult('')
    }

  return (
    <div className="calculatorContainer">
        <div className="calculator">
            <div className="display">
                { result ? (<span>{result} </span>) : '' }
                { calc || '0' }
            </div>
            <div className="operators">
                <button className="operatorsBtn" onClick={() => updateCalc('/')} >/</button>
                <button className="operatorsBtn" onClick={() => updateCalc('*')} >*</button>
                <button className="operatorsBtn" onClick={() => updateCalc('+')} >+</button>
                <button className="operatorsBtn" onClick={() => updateCalc('-')} >-</button>
                <button className="operatorsBtn" onClick={deleteLast} >DEL</button>
                <button className="operatorsBtn" onClick={resetAll} >C</button>
            </div>
            <div className="digits">
                { createDigits() }
                <button className="digitsBtn" onClick={() => updateCalc('.')} >.</button>
                <button className="digitsBtn" onClick={() => updateCalc('0')} >0</button>
                <button className="digitsBtn" onClick={calculate}>=</button>
            </div>
        </div>
    </div>
  )
}

export default Calculator
