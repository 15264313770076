import React from 'react'
import './snakeStyles.css'

type Props = {
    dot: number[]
}
const Food: React.FC<Props> = ({dot}) => {
    const style ={
        left: `${dot[0]}%`,
        top: `${dot[1]}%`
    }
    return <div className="snakeFood" style={style}></div>
}
export default Food