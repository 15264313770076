import React, { useState } from "react";
import { nanoid } from "nanoid";
import "./budgetStyles.css";
import { BudgetType } from "../../../Types/Types";

type Props = {addExpense: (val: BudgetType) => void}
const AddExpenseForm: React.FC<Props> = ({ addExpense }) => {
    const [name, setName] = useState<string>("");
    const [cost, setCost] = useState<number>(0);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const newExpense: BudgetType = {
            id: nanoid(),
            name: name,
            cost: Number(cost)
        };
        addExpense(newExpense);
        setCost(0);
        setName("");
    };

    return (
        <form className="budgetForm" onSubmit={(e) => onSubmit(e)}>
            <div className="formgroup">
                <div className="inputgroup">
                    <label htmlFor="name">Name</label>
                    <input
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        required
                        placeholder="New expense ..."
                    />
                </div>
                <div className="inputgroup">
                    <label htmlFor="cost">Cost</label>
                    <input
                        onChange={(e) => setCost(Number(e.target.value))}
                        type="number"
                        name="cost"
                        id="cost"
                        required
                        min={0}
                    />
                </div>
            </div>

            <button type="submit">Save</button>
        </form>
    );
};

export default AddExpenseForm;
