import React from 'react'
import { Link } from 'react-router-dom'
import './navStyles.css'

const Navmenu: React.FC = () => {

    return (
        <nav className="navbar">  
            <ul className="navList">
                <li>
                    <Link to='/' className="navLinkItem">Home</Link>        
                </li>
                <li>
                    <Link to='/useful_things' className="navLinkItem">Useful things</Link> 
                </li>
                <li>
                    <Link to='/contact' className="navLinkItem">Contact me</Link> 
                </li>
                <li>
                    <Link to='/cv' className="navLinkItem">CV</Link>        
                </li>
            </ul>           
            
        </nav>
    )
}
export default Navmenu