import { BudgetType } from "../Types/Types"

const budgetData: BudgetType[] = [
    {
        id: "we",
        name: "Mobil",
        cost: 6000
    },
    {
        id: "whj",
        name: "Gas bill",
        cost: 25000
    },
    {
        id: "wee2",
        name: "Surprize to John",
        cost: 42900
    },
]
export default budgetData